import React from 'react';
import Hero from 'src/ui-treact/components/hero/BackgroundAsImage';
 
const MainHero = ({data,page}) => {
   
  let finalData= data?.schema_json;  

    let content= {
        imgSrc:'',
        show_buttons:true,
        backgroundColor:'',
        tagLine: '',
        notification:'',
        navLinks:[
          {
            id: 1,
            text: "Optimization",
            url: "/",
            isMain: false,
          },
          {
            id: 2,
            text: "Analytics & Data",
            url: "/about/",
            isMain: false,
          },
          {
            id: 3,
            text: "Shopify",
            url: "/projects/",
            isMain: false,
          },
        ], 
        buttons:[
          {
            id: 1,
            text: 'Check My Site',
            href: '/pages/pagespeed/',
            backgroundColor: '#023047',
            textColor:'#fff'
          },
          {
            id: 2,
            text: 'About',
            href: '/pages/contact-us/',
            backgroundColor: '#219EBC',
            textColor:'#fff'
          },
        ], 
        ...finalData
    }  
    return ( 
       <Hero content={content}/>
    ); 
}
 
export default MainHero;
